import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`1. INTRODUCTION`}</h2>
    <p>{`1.1. These Terms of Service apply to all Clients and/or Users.`}</p>
    <p>{`1.2. Please read these terms of service carefully. The use of the Website(s) and/or the Application(s) by a Client and/or User is offered subject to acceptance of all of the Terms of Service. By accepting these Terms of Service or by proceeding to access or make use of the Website(s) and Application(s), the Client and/or User will become automatically bound by these Terms of Service. From time to time these Terms of Service may be amended by Drive Revenue and the continued use of the Website(s) and/or the Application(s) shall constitute the Client’s and/or User’s acceptance of such amendments. Accordingly you should check the Terms of Service regularly, but in any event the Client and/or User will receive notification of such changes.`}</p>
    <p>{`1.3. It is important to take note of the warranties, indemnities and limitations on liability provided for in these Terms of Service, including but not limited to, those contained in clauses 7.4, clauses 12.8 and 12.9 and in clause 13.`}</p>
    <h2>{`2. DEFINITIONS`}</h2>
    <p>{`In these Terms of Service which include the provisions in clause 1 hereof the following terms shall bear the following meanings:`}</p>
    <p>{`2.1. “Account(s)” means a designated username and password which enables a User to sign in and utilise the Application(s);`}</p>
    <p>{`2.2. “Application(s)” means services accessed through the universal resource locators (“URLS”): url app.drivepractice.com, and/or url app.drivechambers.com, and such other applications that may be offered on URLS provided by Drive Revenue from time to time;`}</p>
    <p>{`2.3. “Client” means any person or legal entity who signs up with Drive Revenue for an Account(s) and who is responsible for payment of the charges levied by Drive Revenue in respect of a User who have Account(s) associated with and/or linked to the Client;`}</p>
    <p>{`2.4. “Drive Revenue” means Drive Revenue Proprietary Limited (Registration number 2015/105716/07), the entity entitled to publish the Website(s) and to provide Account(s) for the access and use of the Application(s);`}</p>
    <p>{`2.5. “Intellectual Property” means all intellectual property, whether or not registrable, including, but not limited to, copyright, patents, proprietary material, trademarks, designs, know-how, methods, techniques, processes, adaptations, ideas, and technical specifications in respect of the Website(s), Application(s) and Account(s);`}</p>
    <p>{`2.6. “Services” means any support services provided by Drive Revenue in relation to the Website(s) and/or Application(s) and/or Account(s);`}</p>
    <p>{`2.7. “User” means any person who accesses the Website(s) from time to time and/or has an Account, and is associated with or employed or engaged with the Client;`}</p>
    <p>{`2.8. “Terms of Service” means the terms and conditions set out in this document, and as may be varied from time to time by Drive Revenue in terms hereof; and`}</p>
    <p>{`2.9. “Website(s)” means the content accessed at the URLS:`}</p>
    <p>{`2.9.1. `}<a parentName="p" {...{
        "href": "http://www.driverevenue.net"
      }}>{`www.driverevenue.net`}</a>{`;`}</p>
    <p>{`2.9.2. `}<a parentName="p" {...{
        "href": "http://www.drivepractice.com"
      }}>{`www.drivepractice.com`}</a>{`;`}</p>
    <p>{`2.9.3. `}<a parentName="p" {...{
        "href": "http://www.drivechambers.com"
      }}>{`www.drivechambers.com`}</a>{`; or such other URL that may be published by Drive Revenue from time to time.`}</p>
    <h2>{`3. INTERPRETATION`}</h2>
    <p>{`3.1. In the Terms of Service headings are used for reference purposes only and shall not affect the meaning of the clause to which they apply.`}</p>
    <p>{`3.2A. Reference to natural persons include legal persons and vice versa. References to persons include an individual, a body corporate, and an unincorporated association of persons. Such a reference also includes a person’s successors and permitted assigns unless the context indicates otherwise.`}</p>
    <p>{`3.3A. Reference to the singular includes the plural and vice versa.`}</p>
    <p>{`3.4. The computation of periods prescribed in terms of any number of days shall be a reference to calendar days and shall be reckoned exclusive of the first day and inclusive of the last day.`}</p>
    <p>{`3.5. No provision herein shall be construed against or interpreted to the disadvantage of Drive Revenue on the basis of it having or being deemed to have structured, drafted or introduced a provision in the Terms of Service.`}</p>
    <h2>{`4. JURISDICTION`}</h2>
    <p>{`These terms and conditions shall be governed by the laws of the Republic of South Africa and shall be subject to the non-exclusive jurisdiction of the Courts of the Republic of South Africa.`}</p>
    <h2>{`5. ACCESS TO WEBSITE(S) AND/OR APPLICATION(S)`}</h2>
    <p>{`5.1. Access to the Website(s) and/or Application(s) is provided by Drive Revenue subject to the Terms of Service.`}</p>
    <p>{`5.2. The Client and User are obliged to comply with all applicable laws and regulatory requirements relating to the use of the Website(s) and/or Application(s).`}</p>
    <p>{`5.3. In order to ensure optimal use of the Website(s) and/or Application(s) each User is required to follow all reasonable instructions given by Drive Revenue from time to time in respect of the use of the Website(s) and Application(s).`}</p>
    <p>{`5.4. The Client is responsible for all charges relating the User’s use of his Account and Application(s) at the prevailing charges of Drive Revenue in respect thereof from time to time.`}</p>
    <p>{`5.5. Drive Revenue reserves the right to update, modify, restrict access to or discontinue the Website(s) and/or Application(s) or any part thereof at any time within its sole and absolute discretion.`}</p>
    <h2>{`6. MATERIAL PROVIDED BY THE CLIENT AND/OR USER`}</h2>
    <p>{`6.1. The Client and User shall be obliged to ensure that all information captured in the Application(s) comply with all applicable laws and regulations, does not infringe Drive Revenue’s rights or the rights of any third party, is not unreliable, misleading or otherwise objectionable and is free of bugs, worms, viruses or other malicious code or software.`}</p>
    <p>{`6.2. The Client and the User are solely responsible for the capture of any information or data in utilising the Application(s).`}</p>
    <h2>{`7. SIGN-UP AND USE OF THE ACCOUNT(S) AND APPLICATION(S)`}</h2>
    <p>{`7.1. The Client and User warrant that the information that is supplied to Drive Revenue in the application for an Account will be true, accurate and complete in all respects.`}</p>
    <p>{`7.2. Drive Revenue reserves the right to reject or terminate access to an Account(s) and/or to Application(s) in the event of a Client and/or User providing false or misleading information during sign-up. The Client and/or User by accepting the Terms of Service hereby grant Drive Revenue the right to do searches and background checks in respect of the Client and/or the User, including but not limited to, conducting credit bureau checks in respect of the Client and/or User.`}</p>
    <p>{`7.3. The Client and/or User agree to keep Drive Revenue informed of any change in their information from time to time to the extent that such information differs from that provided to Drive Revenue at the time of initial sign-up of an Account(s).`}</p>
    <p>{`7.4. The Client and/or User hereby agree to indemnify, defend and hold harmless Drive Revenue, its affiliates and their officers, directors, employees, agents, licensors and suppliers from any and all liabilities, fines, losses, expenses, legal costs and damages resulting from the supply of information during the sign-up process which is not true, accurate and correct or arising from the Client’s and/or a User’s failure to update such information as required in terms of 7.3.`}</p>
    <h2>{`8. GRANT OF LICENSE`}</h2>
    <p>{`8.1. Drive Revenue grants the Client and/or the User a non-exclusive, world wide, and non-transferable license to use the Application(s) through the Account(s) strictly in accordance with these Terms of Service.`}</p>
    <p>{`8.2. The Client and/or User shall not be entitled to sub license, redistribute or resell an Account(s) or allow access to an Account(s) by any third person(s) under any circumstances.`}</p>
    <p>{`8.3. The Client and/or User agree not to translate, function map or create derivative works based on the Application(s), nor reverse assemble, de compile or reverse engineer the Application(s), whether in whole or in part, or otherwise attempt to utilise any source code in respect of the Application(s).`}</p>
    <p>{`8.4. The Client and/or User are entirely responsible for any and all acts and activities in utilizing the Website(s) and/or the Application(s) and/or the Account(s).`}</p>
    <h2>{`9. CHARGES AND COMMERCIAL TERMS`}</h2>
    <p>{`9.1. The Client shall be liable for all charges levied in respect of all the Users associated with the Client that have an Account.`}</p>
    <p>{`9.2. It is the Client’s responsibility to familiarise itself with the prevailing charges, billing frequencies and payment terms in respect of the Account(s). Drive Revenue reserves the right to modify its charges and payment terms at any time on not less than one calendar months’ notice to the Client.`}</p>
    <p>{`9.3. Telecommunications network service providers’ access charges for use of their networks may be varied by network operators from time to time and without prior notice to Drive Revenue. The Client shall be responsible for any and all data and telecommunications charges incurred by the Client and/or the User in relation to the use of the Website(s) and/or Application(s) as these charges are not included in the charges levied from time to time by Drive Revenue in respect of all the Users associated with the Client that have an Account.`}</p>
    <p>{`9.4. Payment by the Client of the charges of Drive Revenue shall be effected by the Client in advance according to the billing cycle chosen by the Client when signing up and the stipulated dates upon which payment of charges is to be made by the client to Drive Revenue. In this regard, the Client shall be rendered with an invoice from Drive Revenue. Such invoice shall be payable strictly on the terms reflected on the invoice. The Client shall ensure that payment of the invoice is made timeously. Failure by the Client to make payment of the invoice rendered by Drive Revenue to the Client as aforesaid shall entitle Drive Revenue to immediately terminate access to the Application(s) by the Client and/or User without giving any notice to the Client and/or User.`}</p>
    <p>{`9.5. All charges are exclusive of the applicable taxes.`}</p>
    <h2>{`10. REFUND`}</h2>
    <p>{`Drive Revenue has a no-refund policy.`}</p>
    <h2>{`11. TERMINATION`}</h2>
    <p>{`11.1. The Client may terminate Account(s) by disabling the Account(s) through the functionality of the Application(s) which may be done at any time, provided that the Client shall remain responsible for any charges already levied in advance in respect of the billing cycle in which such termination occurs.`}</p>
    <p>{`11.2. The Client shall be solely responsible for ensuring that the Account(s) is/are properly terminated through the functionality of the Application(s).`}</p>
    <h2>{`12. INFORMATION SECURITY AND PRIVACY`}</h2>
    <p>{`12.1. These Terms of Service must be read in conjunction with Drive Revenue’s Privacy Policy which shall be deemed to form part of these Terms of Service.`}</p>
    <p>{`12.2. By accepting these Terms of Service in the manner prescribed on the Website(s), the Client and/or User undertake to comply with any applicable data protection laws and legislation.`}</p>
    <p>{`12.3. Drive Revenue is committed to maintaining the security of information hosted and processed through the Account(s) and Application(s) and undertakes to put information security measures in place to protect the security and confidentiality of all data residing within the Application(s) within the jurisdiction in which the applicable data is housed and processed, subject to any disclosure being lawfully requested in terms of applicable legislation, a court order of competent jurisdiction or other regulatory mechanisms provided that should such a disclosure be lawfully requested then the Client and/or User shall be notified by Drive Revenue that such a disclosure has been requested prior to such disclosure being made by Drive Revenue.`}</p>
    <p>{`12.4. The information security measures that have been put in place are constantly upgraded to keep pace with commercial best practice and Drive Revenue’s security policies and procedures may accordingly be revised or updated from time to time.`}</p>
    <p>{`12.5. The Client’s and/or User’s access is controlled through a secure login requiring a valid username and password.`}</p>
    <p>{`12.6. The Client shall be obliged to ensure that an Account is used by a single User and is restricted to the authorised User only.`}</p>
    <p>{`12.7. The Client and/or User are to ensure that the hardware and software used to access the Application(s) remains updated and is secure at all times.`}</p>
    <p>{`12.8. ByThe Client and/or User are solely responsible for maintaining the security and confidentiality of the Account(s), and shall remain solely responsible for any and all activities and use in respect of the Account(s). The Client and/or the User hereby indemnify Drive Revenue in respect of any and all claims and/or damages arising from the failure to maintain such security and confidentiality of the information captured in respect of the Account(s) that may be suffered by the Client and/or User and/or any third party arising from such failure including all and any legal costs arising therefrom.`}</p>
    <p>{`12.9. ByThe Client and/or User undertake to immediately notify Drive Revenue of any known or suspected unauthorised use of the Account(s) or any other security breaches in respect of the Account(s), the Website(s), or the Application(s). Drive Revenue shall not be liable for any acts or omissions on the part of the Client or the User including damages of any kind arising from any acts or omissions from the Client or User arising from such unauthorised use or any other security breaches.`}</p>
    <h2>{`13. WARRANTIES, LIABILITY AND INDEMNITY`}</h2>
    <p>{`13.1. Drive Revenue cannot and does not guarantee that the Website(s) and/or Application(s) and/or Account(s) will operate in accordance with the Client’s and/or User’s specific requirements or that same will be uninterrupted or error free.`}</p>
    <p>{`13.2. Drive Revenue shall not be liable for any delay, failure, breakdown, damage, loss, cost, claim, penalty, fine, legal expenses or expense arising from,`}</p>
    <p>{`13.2.1. The use of the Application(s) or the Services;`}</p>
    <p>{`13.2.2. Operator error on the part of the Client and/or User; and/or`}</p>
    <p>{`13.2.3. Fault in hardware, third party software or software supplied to or obtained by the Client or User in utilizing the Website(s) and/or Application(s) and/or Account(s); and/or`}</p>
    <p>{`13.2.4. The intentional or negligent act or omission of any person not being a duly authorised employee, sub-contractor, or agent of Drive Revenue, or any third party not authorised to act in terms of these Terms of Service; and/or`}</p>
    <p>{`13.2.5. The actions, omissions or service interruptions in respect of the services provided by a utilities provider or third party service provider, including a telecommunications network service providers.`}</p>
    <p>{`13.3. Drive Revenue shall not be liable for loss of data, loss of profits or for indirect, incidental, special or consequential damages arising out of or in connection with,`}</p>
    <p>{`13.3.1. The performance or use of the Account(s) and/or Application(s) and/or Services, and/or the Website(s); and/or`}</p>
    <p>{`13.3.2. Arising from the Client’s or User’s incorporation or attachment of any program, plug-in or device to the Website(s) or Application(s); and/or`}</p>
    <p>{`13.3.3. Changes to the Application(s) utilized by the Client and/or User from time to time; and/or`}</p>
    <p>{`13.3.4. Failure on the part of the Client or User to provide a suitable operating environment for the user of the Website(s) and/or Application(s) and/or Account(s); and/or`}</p>
    <p>{`13.3.5. Any failure on the part of the Client and/or User to backup any data captured using the Application(s); and/or`}</p>
    <p>{`13.3.6. Use of the Website(s) and/or Application(s) for any purpose other than that for which Website(s) and/or Application(s) were designed.`}</p>
    <p>{`13.4. Use of the Website(s) and/or Application(s) is entirely at the Client’s and/or User’s own risk and it is understood and acknowledged by the Client and/or the User that no warranties are given by Drive Revenue with respect to any common law, statutory or regulatory requirement or obligation to which the Client and/or User may be subject to.`}</p>
    <p>{`13.5. The Client and/or User are entirely responsible for any and all acts and activities in utilising the Account(s) and Application(s). The Client and/or User further agree to indemnify, defend and hold harmless Drive Revenue, its affiliates and their officers, directors, employees, agents, licensors and suppliers from any and all liabilities, fines, losses, expenses, legal costs and damages resulting from any activity related to the use of the Account(s) and the Application(s) (including negligent, unlawful or wrongful conduct) by the Client and/or the User.`}</p>
    <p>{`13.6. The Client and/or User hereby indemnify and hold Drive Revenue harmless against any claim by a third party arising out of the use of the Website(s) and/or the Application(s) and/or the Account(s) by the Client and/or User. The Client and/or User further agree to indemnify, defend and hold harmless Drive Revenue, its affiliates and their officers, directors, employees, agents, licensors and suppliers from any and all claims from such third parties and all expenses and legal costs relating to such a claim.`}</p>
    <p>{`13.7. Drive Revenue may from time to time provide hyperlinks to websites not controlled by Drive Revenue and such hyperlinks shall not imply any endorsement, agreement or support for the content of such websites. The Client and/or the User acknowledges that Drive Revenue does not control or monitor the content of such websites and shall not be liable for the access to, inability to access, or the content available on or through such hyperlinks.`}</p>
    <h2>{`14. MAINTENANCE`}</h2>
    <p>{`Drive Revenue shall be entitled from time to time make improvements, changes or upgrades to the Website(s) or the Application(s) on reasonable notice to the Client and/or User, such notice to be given by Drive Revenue of the suspension or interruption of the operation of the Website(s) and/or Application(s) and/or Account(s) for the purposes of maintenance or making improvements, changes or upgrades thereto.`}</p>
    <h2>{`15. INTELLECTUAL PROPERTY`}</h2>
    <p>{`15.1. The Client and/or User acknowledge that they have no rights in and to the Intellectual Property Rights.`}</p>
    <p>{`15.2. Nothing contained in the Terms and Conditions shall convey any title or proprietary rights or interest to the Client and/or the User in or to the Intellectual Property.`}</p>
    <p>{`15.3. The Client and/or User hereby grants Drive Revenue a royalty-free, perpetual, irrevocable, non-exclusive, transferable licence to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display any content supplied to the Application(s) by the Client/User worldwide in order to incorporate it in other works in any form, media, or technology now known or later developed, in order to facilitate the functioning and use of the Application(s).`}</p>
    <h2>{`16. BREACH`}</h2>
    <p>{`In the event of the Client and/or User breaching any of the Terms of Service and failing to rectify such breach within 3 (three) days of written notice from Drive Revenue to rectify such breach, then Drive Revenue shall be entitled, but not obliged, to deny the Client and/or User access to the Account(s) and/or Application(s). In the event of Drive Revenue terminating access to the Account(s) and/or Application(s), such termination shall be without prejudice to Drive Revenue’s rights to claim any unpaid fees and/or damages that it may be entitled to in accordance with the Terms of Service set out herein or at law.`}</p>
    <h2>{`17. MISCELLANEOUS`}</h2>
    <p>{`17.1. Independent Advice`}</p>
    <p>{`The Client and/or the User acknowledge that they have been free to secure independent legal advice as to the nature and effect of all of the provisions of these Terms of Service and that they have either taken such independent legal advice or dispensed with the necessity of doing so. Further, they acknowledge that all of the provisions of these Terms of Service and the restrictions herein contained are fair and reasonable in all the circumstances.`}</p>
    <p>{`17.2. Assignment`}</p>
    <p>{`Save as expressly set out herein, the Client and/or the User shall not be entitled to assign any of rights or delegate any obligations under the Terms of Service.`}</p>
    <p>{`17.3. Whole Agreement`}</p>
    <p>{`The Terms of Service shall constitute the whole agreement between the Client and/or User and Drive Revenue as to the subject matter hereof and no other or previous agreement, representations or warranties, express or implied made either prior to or after acceptance of these Terms of Service by the Client and/or the User other than those set in these Terms of Service shall be binding on the Client and/or User and Drive Revenue.`}</p>
    <p>{`17.4. Variation`}</p>
    <p>{`No addition to or variation or novation of the Terms of Service shall be of any force or effect whatsoever save for amendments made to the Terms of Service as provided for herein.`}</p>
    <p>{`17.5. Relaxation`}</p>
    <p>{`No latitude, extension of time or other indulgence which may be given or allowed by Drive Revenue in respect of the performance of any obligation hereunder or enforcement of any right arising from this Terms of Service and no single or partial exercise of any right by Drive Revenue shall under any circumstances be construed to be an implied consent by Drive Revenue or operate as a waiver or a novation of, or otherwise affect any of Drive Revenue’s rights in terms of or arising from the Terms of Service or estop Drive Revenue from enforcing, at any time strict and punctual compliance with each and every provision or term of the of Service.`}</p>
    <p>{`17.6. Severability`}</p>
    <p>{`If any term or provision or part thereof (in this clause called (“the offending provision”)) contained in the Terms of Service shall for any reason whatsoever be declared or become unenforceable, invalid, or illegal including but without derogating from the generality of the aforegoing, a decision by any court, an Act of Parliament or any statutory or other by laws or regulations or any other requirements having the force of law, the other terms and provisions of the Terms of Service shall remain in full force and effect as if the Terms and Conditions did not have the offending provision appearing therein.`}</p>
    <p>{`17.7. Communication`}</p>
    <p>{`Any notice or other communication given under or in terms of these Terms of Service must be in the English language.`}</p>
    <p>{`17.8. Notices`}</p>
    <p>{`All notices given in terms of these Terms of Service shall be in writing and shall be delivered electronically via email to the designated email account of the Client and/or User which the Client and/or User acknowledge shall be a valid mode of notice, and the Client and/or User acknowledge that such notifications shall be deemed to have been received within 24 hours from when dispatched to them. The Client and/or User shall ensure that Drive Revenue at all material times is provided with any changes to the Client’s and/or User’s e mail address for the purposes of Drive Revenue giving notices in terms of the Terms of Service .`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      